import * as React from 'react';

import './App.css';
import { Route, Routes, useLocation, useParams, useSearchParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import OrderContainer from './container/Order.container';
import ErrorBoundary from './screens/Error.page';
import urlJoin from 'url-join';
import { VENDAH_MAIN_URL } from './const';
import CheckoutContainer from './container/Checkout.container';
import RegistrationContainer from './container/Registration.container';
import RegistrationMessageContainer from './container/RegistrationMessage.container';
import { getResellerId, getToken, setResellerId } from './client/vendah/orderPaymentService/draw-ticket';
import DrawTicketPhonePage from './screens/DrawTicketPhone.page';
import DrawTicketTicketsPage from './screens/DrawTicketTickets.page';
import DrawTicketVerificationCodePage from './screens/DrawTicketVerificationCode.page';
import DrawTicketRegistrationPage from './screens/DrawTicketRegistration.page';
import DrawTicketShopPage from './screens/DrawTicketShop.page';
import DrawTicketOrderContainer from './container/DrawTicketOrder.container';

function NotFound() {
    return <ErrorBoundary error={new Error('Página não encontrada!')} />;
}

function RedirectToStore({ suffix }: { suffix?: string }) {
    const { customName } = useParams();

    const r = suffix ? `${customName}/${suffix}` : customName;

    window.location.replace(urlJoin(VENDAH_MAIN_URL, 'pages', `loja?r=${r}`));

    return null;
}

function RedirectDrawTicket({ callback }: { callback: string }) {
    const [searchParams] = useSearchParams();
    setResellerId(searchParams.get('id'));

    if (!getResellerId()) {
        throw new Error(
            'Parece que houve algum problema com seu link. Peça para sua revendedora um link para fazer sua compra'
        );
    }

    const token = getToken();
    if (!token) {
        return <DrawTicketPhonePage callback={callback} />;
    }

    return callback === 'bilhetes' ? <DrawTicketTicketsPage /> : <DrawTicketShopPage />;
}

function NavigateDrawTicket({ action }: { action: string }) {
    const { state } = useLocation();

    if (action === 'code') return <DrawTicketVerificationCodePage callback={state.callback} phone={state.phone} />;

    return <DrawTicketRegistrationPage phone={state.phone} />;
}

function RedirectOldDrawTicketUrl() {
    window.location.replace(window.location.href.replace('cupom-premiado', 'vale-compras-premiado'));
    return null;
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
    return (
        <SentryRoutes>
            <Route path="/*/pedido/:orderHash" element={<OrderContainer />} />
            <Route path="/pedido/:orderHash" element={<OrderContainer />} />
            <Route path="/checkout/:resellerId/:items" element={<CheckoutContainer />} />
            <Route path="/checkout/:items" element={<CheckoutContainer />} />
            <Route path="/cadastro/:step" element={<RegistrationContainer />} />
            <Route path="/mensagem" element={<RegistrationMessageContainer />} />
            <Route path="/:customName/sp" element={<RedirectToStore suffix="sp" />} />
            <Route path="/:customName" element={<RedirectToStore />} />
            <Route path="/cupom-premiado" element={<RedirectOldDrawTicketUrl />} />
            <Route path="/cupom-premiado/bilhetes" element={<RedirectOldDrawTicketUrl />} />
            <Route path="/cupom-premiado/comprar" element={<RedirectOldDrawTicketUrl />} />
            <Route path="/cupom-premiado/codigo" element={<RedirectOldDrawTicketUrl />} />
            <Route path="/cupom-premiado/registro" element={<RedirectOldDrawTicketUrl />} />
            <Route path="/vale-compras-premiado" element={<RedirectDrawTicket callback="bilhetes" />} />
            <Route path="/vale-compras-premiado/bilhetes" element={<RedirectDrawTicket callback="bilhetes" />} />
            <Route path="/vale-compras-premiado/comprar" element={<RedirectDrawTicket callback="comprar" />} />
            <Route path="/vale-compras-premiado/codigo" element={<NavigateDrawTicket action="code" />} />
            <Route path="/vale-compras-premiado/registro" element={<NavigateDrawTicket action="register" />} />
            <Route path="/vale-compras-premiado/:orderHash" element={<DrawTicketOrderContainer />} />
            <Route path="*" element={<NotFound />} />
        </SentryRoutes>
    );
}

export default App;
