import * as React from 'react';
import { useParams } from 'react-router-dom';
import urlJoin from 'url-join';
import Spinner from '../components/Spinner';
import { OPS_API_URL } from '../const';
import DrawTicketExpiredPage from '../screens/DrawTicketExpired.page';
import DrawTicketPaidPage from '../screens/DrawTicketPaid.page';
import DrawTicketPendingPage from '../screens/DrawTicketPending.page';

export enum DrawTicketOrderStatus {
    Pending = 'PENDING',
    Paid = 'PAID',
    Expired = 'EXPIRED',
}

export type LineItem = {
    name: string;
    quantity: number;
    image?: {
        url: string;
        altText?: string;
    };
};

export type DrawTicketOrder = {
    customer: {
        cpf: string;
        phone: string;
        fullName: string;
    };
    paymentUrl?: string;
    status: DrawTicketOrderStatus;
    totalAmount: number;
    quantity: number;
    externalReference: string;
};

export const DrawTicketOrderContext = React.createContext(undefined) as React.Context<DrawTicketOrder | undefined>;

function DrawTicketOrderContainer(): JSX.Element | null {
    const { orderHash } = useParams();
    const [order, setOrder] = React.useState<DrawTicketOrder>();
    const [error, setError] = React.useState<Error>();

    // Get order from OPS
    React.useEffect(() => {
        if (!order && !error) {
            const fetchOrder = async () => {
                const response = await fetch(urlJoin(OPS_API_URL, 'draw-ticket', 'orders', orderHash!));
                if (response.status === 404) {
                    setError(new Error('Pedido não encontrado!', { cause: response as any }));
                } else if (response.status >= 400) {
                    setError(new Error('Erro ao procurar pedido!', { cause: response as any }));
                } else {
                    const order = await response.json();
                    gtag('event', 'get-draw-ticket-order', { order });
                    setOrder(order);
                }
            };

            fetchOrder().catch(error => {
                console.error(error);
                setError(error);
            });
        }
    }, [order, orderHash, error]);

    if (error) throw error;

    if (!order) return <Spinner />;

    let Page = null;
    if (order.status === DrawTicketOrderStatus.Paid) {
        Page = <DrawTicketPaidPage />;
    } else if (order.status === DrawTicketOrderStatus.Expired) {
        Page = <DrawTicketExpiredPage />;
    } else if (order.status === DrawTicketOrderStatus.Pending) {
        Page = <DrawTicketPendingPage />;
    }

    return <DrawTicketOrderContext.Provider value={order}>{Page}</DrawTicketOrderContext.Provider>;
}

export default DrawTicketOrderContainer;
