import urlJoin from 'url-join';
import { OPS_API_URL } from '../../../const';
import { executeRequest } from '../../utils';
import { formatOnlyDigits } from '../../../utils/format';
import { NavigateFunction } from 'react-router-dom';
import { PaymentQrcode, PaymentQrcodeRequest } from './payment-qrcode';

export function getToken() {
    return localStorage.getItem('vendah.token') ?? '';
}

function setToken(token: string) {
    localStorage.setItem('vendah.token', token ? `Bearer ${token}` : '');
}

export function logoff() {
    localStorage.setItem('vendah.token', '');
    localStorage.setItem('vendah.customerName', '');
}

export function getResellerId() {
    return localStorage.getItem('vendah.resellerId') ?? '';
}

export function setResellerId(resellerId: string | null) {
    if (resellerId) localStorage.setItem('vendah.resellerId', resellerId);
}

export function getCustomerName() {
    return localStorage.getItem('vendah.customerName') ?? '';
}

function setCustomerName(customerName: string) {
    localStorage.setItem('vendah.customerName', customerName);
}

type CustomerUnregisteredResponse = {
    success: false;
    status_code: number;
};

type CustomerRegisteredResponse = {
    success: true;
    newToken: string;
    customer?: Customer;
    status_code: number;
};

type Customer = {
    cpf: string;
    phone: string;
    email?: string;
    fullName: string;
};

type CustomerResponse = CustomerUnregisteredResponse | CustomerRegisteredResponse;

export async function getCustomer(navigate: NavigateFunction): Promise<CustomerResponse> {
    const response: CustomerResponse = await executeRequest(urlJoin(OPS_API_URL, 'customer'), {
        headers: { Authorization: getToken() },
    });
    console.log('get customer', response);
    handleAuthenticatedResponse(response, navigate);

    return response;
}

export async function sendVerificationCode(phone: string) {
    const response = await executeRequest(
        urlJoin(OPS_API_URL, 'customer', 'phone', formatOnlyDigits(phone), 'verification-code')
    );
    console.log('checking phone response', response);

    return response;
}

export async function validadeVerificationCode(phone: string, code: string, navigate: NavigateFunction) {
    const response = await executeRequest(
        urlJoin(OPS_API_URL, 'customer', 'phone', formatOnlyDigits(phone), 'verification-code'),
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ code }),
        },
        { ignoreStatus: true }
    );
    console.log('verification code response', response);
    handleAuthenticatedResponse(response, navigate);

    return response;
}

export async function customerRegistration(
    data: { cpf: string; phone: string; email: string; fullName: string },
    navigate: NavigateFunction
) {
    const response = await executeRequest(
        urlJoin(OPS_API_URL, 'customer'),
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: getToken(),
            },
            body: JSON.stringify(data),
        },
        { ignoreStatus: true }
    );
    console.log('post customer response', response);
    handleAuthenticatedResponse(response, navigate);

    return response;
}

export type DrawTickets = {
    success: boolean;
    newToken: string;
    drawTickets: DrawTicket[];
    previousMonth?: number;
    yearMonth: number;
};

type DrawTicket = {
    monthLuckyNumber: string;
    coupon: string;
    amount: number;
    createdAt: number;
};

export async function getDrawTickets(navigate: NavigateFunction, yearMonth?: number) {
    const response: DrawTickets = await executeRequest(
        urlJoin(OPS_API_URL, 'draw-ticket', yearMonth ? yearMonth.toString() : 'latest'),
        { headers: { 'Content-Type': 'application/json', Authorization: getToken() } },
        { ignoreStatus: true }
    );
    handleAuthenticatedResponse(response, navigate);

    return response;
}

export type DrawTicketPaymentUrlResponse = {
    paymentUrl: string;
    totalAmount: number;
    externalReference: string;
    newToken: string;
    success: boolean;
    error?: string;
};

export async function buyDrawTickets(data: any, navigate: NavigateFunction): Promise<DrawTicketPaymentUrlResponse> {
    const response: DrawTicketPaymentUrlResponse = await executeRequest(
        urlJoin(OPS_API_URL, 'draw-ticket'),
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: getToken(),
            },
            body: JSON.stringify(data),
        },
        { ignoreStatus: true }
    );
    handleAuthenticatedResponse(response, navigate);

    return response;
}

export async function getDrawTicketQrcode(data: PaymentQrcodeRequest): Promise<PaymentQrcode> {
    return await executeRequest(
        urlJoin(OPS_API_URL, 'draw-ticket', 'qrcode'),
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        },
        { ignoreStatus: true }
    );
}

function handleAuthenticatedResponse(response: any, navigate: NavigateFunction) {
    console.log('handle response', response);
    if (response.success) {
        if (response.newToken) setToken(response.newToken);
        if (response.token) setToken(response.token);
        if (response.customerFullName) setCustomerName(response.customerFullName.split(' ')[0]);
    } else {
        if (response.status_code === 401) {
            navigate('/vale-compras-premiado/phone');
            setToken('');
        }
    }
}
