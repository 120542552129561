import * as React from 'react';
import * as Sentry from '@sentry/react';

import styled from 'styled-components';
import { ActionButton, LinkButton } from '../components/Button';
import VendahLogo from '../components/icons/VendahLogo';
import { LogoContainer } from '../components/OrderStatusLayout';
import Title from '../components/Title';
import { Container, Padding } from './styles';
import { PaymentQrcode } from '../client/vendah/orderPaymentService/payment-qrcode';
import Copy from '../components/icons/copy';
import { useLocalStorageState } from '../utils/useLocalStorage';
import { Input } from '../components';
import { formatCpf, formatPhone, isEmailValid } from '../utils/format';
import { DrawTicketOrderContext } from '../container/DrawTicketOrder.container';
import { getDrawTicketQrcode } from '../client/vendah/orderPaymentService/draw-ticket';

const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 25px;
    gap: 16px;
    line-height: 14px;

    text-align: left;
`;

const LineItemsContainer = styled.div`
    flex-direction: row;
    display: flex;
    font-size: 16px;

    justify-content: center;

    padding: 16px;
`;

const QRCodeContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    margin: 10px 80px;
`;

const QRCodeButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    margin: 10px 40px;
`;

const EmailContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    margin: 16px 10px 10px 10px;
`;

const TotalContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    font-weight: 700;
    font-size: 16px;

    padding: 16px;

    background: linear-gradient(0deg, #f9f9f9, #f9f9f9), #ffffff;
`;

function DrawTicketPendingPage() {
    const [error, setError] = React.useState<Error>();
    const [loadingQrcode, setLoadingQrcode] = React.useState(false);
    const [paymentQrcode, setPaymentQrcode] = React.useState<PaymentQrcode>();
    const [qrcodeInvalidEmail, setQrcodeInvalidEmail] = React.useState(false);
    const [email, setEmail] = useLocalStorageState('qrcode.email');
    const [copiedMessage, setCopiedMessage] = React.useState<string>();

    const emailInvalid = React.useMemo(() => !isEmailValid(email), [email]);

    async function showQrcode(externalReference: string, totalAmount: number): Promise<void> {
        console.log('showing qrcode');
        setLoadingQrcode(true);
        if (loadingQrcode) return;

        try {
            console.log('generating qrcode', { externalReference, totalAmount });
            const response: PaymentQrcode = await getDrawTicketQrcode({
                email: qrcodeInvalidEmail ? email : undefined,
                externalReference,
                totalAmount,
            });

            console.log('get qrcode response', response);
            if (response.success) {
                console.log('Showing QRCode');

                setPaymentQrcode(response);
            } else {
                if (response.errorCode === 'INVALID_EMAIL') {
                    setQrcodeInvalidEmail(true);
                } else {
                    setError(new Error(response.error || 'Erro ao gerar o QRCode'));
                }
            }
        } catch (error: any) {
            Sentry.captureException(error);
            setError(new Error('Erro ao gerar o QRCode'));
        } finally {
            setLoadingQrcode(false);
        }
    }

    async function copyCode(code: string) {
        try {
            await navigator.clipboard.writeText(code);
            setCopiedMessage('Código copiado com sucesso');
        } catch (error: any) {
            setCopiedMessage('Erro ao copiar o Código');
            console.error(error);
            gtag('event', 'error_copy_qrcode');
            Sentry.captureException(error);
        }
    }

    if (error) throw error;

    return (
        <DrawTicketOrderContext.Consumer>
            {order => {
                const { customer, totalAmount, paymentUrl, quantity, externalReference } = order!;

                const totalPrice = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                    Number(totalAmount)
                );

                let PaymentLink;
                if (paymentUrl) {
                    PaymentLink = (
                        <LinkButton color="pink" href={paymentUrl}>
                            <Title>Pagar com Cartão, etc</Title>
                        </LinkButton>
                    );
                } else {
                    PaymentLink = (
                        <LinkButton disabled>
                            Link para pagamento com cartão, etc indisponível. Por favor tente novamente mais tarde ou
                            pague por pix.
                        </LinkButton>
                    );
                }

                return (
                    <Container>
                        <LogoContainer>
                            <VendahLogo />
                        </LogoContainer>
                        <Padding />
                        <Padding />
                        <Padding />
                        <DetailsContainer>
                            <div>Cliente: {customer.fullName}</div>
                            <div>CPF: {formatCpf(customer.cpf)}</div>
                            <div>Cel: {formatPhone(customer.phone)}</div>
                        </DetailsContainer>
                        <Padding />
                        <LineItemsContainer>{quantity} × Números da Sorte</LineItemsContainer>
                        <Padding />
                        <TotalContainer>
                            <p>Total</p>
                            <p>{totalPrice}</p>
                        </TotalContainer>
                        {!paymentQrcode?.success && (
                            <>
                                {qrcodeInvalidEmail && (
                                    <EmailContainer>
                                        <strong>Digite seu E-mail para gerar o PIX</strong>
                                        <Input
                                            id="email"
                                            value={email}
                                            label="E-mail"
                                            onValueChange={text => setEmail(text.trim())}
                                            placeholder="E-mail"
                                            invalidMessage={emailInvalid ? 'E-mail inválido' : undefined}
                                        />
                                    </EmailContainer>
                                )}
                                <ActionButton
                                    disabled={loadingQrcode || (qrcodeInvalidEmail && emailInvalid)}
                                    onPress={() => showQrcode(externalReference, totalAmount)}
                                >
                                    <Title>{loadingQrcode ? 'Carregando...' : 'Pagar com PIX'}</Title>
                                </ActionButton>
                            </>
                        )}
                        {paymentQrcode?.success && (
                            <div>
                                {paymentQrcode.qr_code_base64 && (
                                    <QRCodeContainer>
                                        <img
                                            src={`data:image/png;base64, ${paymentQrcode.qr_code_base64}`}
                                            alt="Red dot"
                                        />
                                    </QRCodeContainer>
                                )}
                                <QRCodeButtonContainer>
                                    <ActionButton
                                        disabled={false}
                                        color={'blue'}
                                        onPress={() => copyCode(paymentQrcode.qr_code)}
                                    >
                                        <Copy />
                                        <Padding />
                                        <Title>Copiar código Pix Copia e Cola</Title>
                                    </ActionButton>
                                    {copiedMessage && <p>{copiedMessage}</p>}
                                </QRCodeButtonContainer>
                            </div>
                        )}
                        {PaymentLink}
                    </Container>
                );
            }}
        </DrawTicketOrderContext.Consumer>
    );
}

export default DrawTicketPendingPage;
