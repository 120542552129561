import urlJoin from 'url-join';
import { OPS_API_URL } from '../../../const';
import { executeRequest } from '../../utils';

export type PaymentQrcode = {
    qr_code: string;
    qr_code_base64?: string;
    success: boolean;
    errorCode?: string;
    error?: string;
};

export async function getQrcode(orderId: string, email?: string): Promise<PaymentQrcode> {
    return await executeRequest(
        urlJoin(OPS_API_URL, 'orders', orderId, 'qrcode'),
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        },
        { ignoreStatus: true }
    );
}

export type PaymentQrcodeRequest = {
    email?: string;
    externalReference: string;
    totalAmount: number;
};
