import * as React from 'react';
import styled from 'styled-components';
import Container from '../components/Container';
import CircledCheckMark from '../components/icons/CircledCheckmark';

import OrderStatusLayout from '../components/OrderStatusLayout';
import Title from '../components/Title';

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    svg {
        margin-right: 6px;
    }
`;

function DrawTicketPaidPage() {
    return (
        <OrderStatusLayout>
            <Container>
                <TitleContainer>
                    <CircledCheckMark />
                    <Title>Esse Vale Compras Premiado já foi pago</Title>
                </TitleContainer>
                <p>Oba! Agora é só esperar os sorteios e boa sorte!.</p>
            </Container>
        </OrderStatusLayout>
    );
}

export default DrawTicketPaidPage;
