import * as React from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';

import { formatUsingMask, isEmailValid, removeTrailingNonDigits, stripNonLetters } from '../utils/format';
import { isCpfValid } from '../utils/validation/cpf';
import { isPhoneValid } from '../utils/validation/phone';
import { Input, LogoContainer, SubmitButton, Title, VendahLogo } from '../components';
import { Container, InputsContainer, Padding, TitleContainer } from './styles';
import { useLocalStorageState } from '../utils/useLocalStorage';
import { useNavigate } from 'react-router-dom';
import Checkbox from '../components/Checkbox';
import { customerRegistration } from '../client/vendah/orderPaymentService/draw-ticket';
import DrawTicketCustomerDetails from '../components/DrawTicketCustomerDetails';

const TermsAcceptedView = styled.div`
    text-align: left;
    align-items: center;

    margin: 10px;
`;

const TermsAcceptedCheckboxView = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    text-align: left;
    align-items: center;

    margin-top: 10px;
    margin-bottom: 10px;
`;

const Form = styled.form``;

interface Props {
    phone: string;
}

function DrawTicketRegistrationPage({ phone }: Props) {
    const [fullName, setFullName] = useLocalStorageState('draw.ticket.fullName');
    const [email, setEmail] = useLocalStorageState('draw.ticket.email');
    const [emailConfirmation, setEmailConfirmation] = useLocalStorageState('draw.ticket.email.confirmation');
    const [cpf, setCPF] = useLocalStorageState('draw.ticket.cpf');
    const [invalidMessage, setInvalidMessage] = React.useState('');
    const [termsAccepted, setTermsAccepted] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const navigate = useNavigate();

    const fullNameInvalid = React.useMemo(() => !fullName || fullName.split(' ').length <= 1, [fullName]);
    const phoneInvalid = React.useMemo(() => !isPhoneValid(phone), [phone]);
    const taxIdInvalid = React.useMemo(() => !isCpfValid(cpf), [cpf]);
    const emailInvalid = React.useMemo(() => !isEmailValid(email), [email]);
    const emailConfirmationError = email !== emailConfirmation ? 'Os E-mails estão diferentes.' : undefined;

    const submitEnabled =
        !fullNameInvalid && !taxIdInvalid && !phoneInvalid && !emailConfirmationError && termsAccepted;

    async function submitForm(evt: any) {
        evt.preventDefault();

        if (!submitEnabled) return;

        setLoading(true);
        setInvalidMessage('');

        try {
            const response = await customerRegistration(
                {
                    cpf,
                    phone,
                    email,
                    fullName,
                },
                navigate
            );
            setLoading(false);

            gtag('event', 'draw_ticket_registration_submit');

            if (response.success) {
                navigate('/vale-compras-premiado/bilhetes');
            } else {
                setInvalidMessage(response.error);
            }
        } catch (error) {
            Sentry.captureException(error);
            setLoading(false);
            setInvalidMessage('Erro ao finalizar o registro');
        }
    }

    return (
        <Container>
            <LogoContainer>
                <VendahLogo />
            </LogoContainer>
            <TitleContainer>
                <Title>Você ainda não tem cadastro. Vamos começar</Title>
            </TitleContainer>
            <DrawTicketCustomerDetails />
            <Form onSubmit={submitForm}>
                <InputsContainer>
                    <Input
                        id="phone"
                        value={phone}
                        label="Celular"
                        onValueChange={() => {}}
                        disabled={true}
                        placeholder="(11) 99999-9999"
                    />
                    <Input
                        id="fullName"
                        value={fullName}
                        label="Nome Completo"
                        onValueChange={text => setFullName(stripNonLetters(text))}
                        placeholder="Maria da Silva"
                        invalidMessage={fullNameInvalid ? 'Nome Completo inválido' : undefined}
                    />
                    <Input
                        id="cpf"
                        value={cpf}
                        label="CPF"
                        placeholder="000.000.000-00"
                        onValueChange={text => {
                            setCPF(removeTrailingNonDigits(formatUsingMask(text, '999.999.999-99')));
                        }}
                        invalidMessage={taxIdInvalid ? 'CPF inválido' : undefined}
                    />
                    <Input
                        id="email"
                        value={email}
                        label="E-mail"
                        onValueChange={text => setEmail(text.trim())}
                        placeholder="E-mail"
                        invalidMessage={emailInvalid ? 'E-mail inválido' : undefined}
                    />
                    <Input
                        id="email"
                        value={emailConfirmation}
                        label="Confirmar E-mail"
                        onValueChange={text => setEmailConfirmation(text.trim())}
                        placeholder="E-mail"
                        invalidMessage={emailConfirmationError}
                    />

                    <TermsAcceptedView>
                        <strong>Termos e condições</strong>
                        <p>Tenho mais de 18 anos de idade;</p>
                        <p>
                            Aceito os{' '}
                            <a target="_blank" rel="noreferrer" href="https://vendah.com.br/pages/termos-e-condicoes">
                                Termos e Condições
                            </a>
                            ;
                        </p>
                        <p>
                            Aceito o{' '}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://vendah.com.br/pages/regulamento-cupom-premiado-assina-ganha"
                            >
                                Regulamento da Promoção Comercial
                            </a>
                            ;
                        </p>
                    </TermsAcceptedView>
                    <TermsAcceptedCheckboxView>
                        <Checkbox
                            checked={termsAccepted}
                            onChange={() => {
                                setTermsAccepted(!termsAccepted);
                            }}
                        />
                        <Padding />
                        <p>Li e estou ciente de todos os Termos e Condições acima.</p>
                    </TermsAcceptedCheckboxView>
                </InputsContainer>
                {!!invalidMessage && <p>{invalidMessage}</p>}
                <SubmitButton disabled={!submitEnabled || loading}>
                    <Title>Cadastrar</Title>
                </SubmitButton>
            </Form>
        </Container>
    );
}

export default DrawTicketRegistrationPage;
