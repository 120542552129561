import * as React from 'react';

import Card from '../components/Card';
import Container from '../components/Container';
import OrderStatusLayout from '../components/OrderStatusLayout';
import Title from '../components/Title';

function DrawTicketExpiredPage() {
    return (
        <OrderStatusLayout>
            <Container>
                <Title>Prazo esgotado</Title>

                <p>Ainda não detectamos o pagamento deste Vale Compras Premiado e o prazo já acabou.</p>
            </Container>

            <Card background={false}>
                <p>
                    Se você não realizou o pagamento, mas ainda quer fazer a compra, fale com seu contato Vendah e{' '}
                    <b>solicite um novo link.</b>
                </p>
            </Card>
        </OrderStatusLayout>
    );
}

export default DrawTicketExpiredPage;
