import * as React from 'react';
import * as Sentry from '@sentry/react';

import { Input, LogoContainer, Spinner, SubmitButton, Title, VendahLogo } from '../components';
import { Container, Padding, TitleContainer } from './styles';
import { useAsyncEffect } from '../hook/useAsyncEffect';
import styled from 'styled-components';
import {
    buyDrawTickets,
    DrawTicketPaymentUrlResponse,
    DrawTickets,
    getDrawTicketQrcode,
    getDrawTickets,
    getResellerId,
} from '../client/vendah/orderPaymentService/draw-ticket';
import { useNavigate } from 'react-router-dom';
import { ActionButton, LinkButton } from '../components/Button';
import Copy from '../components/icons/copy';
import { PaymentQrcode } from '../client/vendah/orderPaymentService/payment-qrcode';
import { useLocalStorageState } from '../utils/useLocalStorage';
import { isEmailValid } from '../utils/format';
import DrawTicketCustomerDetails from '../components/DrawTicketCustomerDetails';

const QRCodeContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    margin: 10px 80px;
`;

const QRCodeButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    margin: 10px 40px;
`;

const EmailContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    margin: 16px 10px 10px 10px;
`;

const Form = styled.form``;

export const InputsContainer = styled.div`
    margin: 0px 16px;
`;

function DrawTicketShopPage() {
    const [quantity, setQuantity] = React.useState('1');
    const [loading, setLoading] = React.useState(false);
    const [drawTickets, setDrawTickets] = React.useState<DrawTickets>();
    const [error, setError] = React.useState<Error>();
    const [invalidMessage, setInvalidMessage] = React.useState('');
    const [drawTicketPaymentUrlResponse, setDrawTicketPaymentUrlResponse] =
        React.useState<DrawTicketPaymentUrlResponse>();
    const [paymentQrcode, setPaymentQrcode] = React.useState<PaymentQrcode>();
    const [loadingQrcode, setLoadingQrcode] = React.useState(false);
    const [qrcodeInvalidEmail, setQrcodeInvalidEmail] = React.useState(false);
    const [copiedMessage, setCopiedMessage] = React.useState<string>();
    const [email, setEmail] = useLocalStorageState('qrcode.email');

    const emailInvalid = React.useMemo(() => !isEmailValid(email), [email]);

    const navigate = useNavigate();

    useAsyncEffect(
        async () => {
            try {
                const response: DrawTickets = await getDrawTickets(navigate);

                return response;
            } catch (error) {
                setError(new Error('Erro ao consultar os números da sorte!'));
                Sentry.captureException(error);
            }
        },
        setDrawTickets,
        []
    );

    async function showQrcode(externalReference: string, totalAmount: number): Promise<void> {
        console.log('showing qrcode');
        setLoadingQrcode(true);
        if (loadingQrcode) return;

        try {
            console.log('generating qrcode', { externalReference, totalAmount });
            const response: PaymentQrcode = await getDrawTicketQrcode({
                email: qrcodeInvalidEmail ? email : undefined,
                externalReference,
                totalAmount,
            });

            console.log('get qrcode response', response);
            if (response.success) {
                console.log('Showing QRCode');

                setPaymentQrcode(response);
            } else {
                if (response.errorCode === 'INVALID_EMAIL') {
                    setQrcodeInvalidEmail(true);
                } else {
                    setError(new Error(response.error || 'Erro ao gerar o QRCode'));
                }
            }
        } catch (error: any) {
            Sentry.captureException(error);
            setError(new Error('Erro ao gerar o QRCode'));
        } finally {
            setLoadingQrcode(false);
        }
    }

    async function submitForm(evt: any) {
        evt.preventDefault();

        if (!quantity || !drawTickets) return;

        setInvalidMessage('');

        const quantityLeftToBuy = 10 - (drawTickets?.drawTickets || []).length;
        console.log('quantityLeftToBuy', quantityLeftToBuy);
        if (parseInt(quantity) > quantityLeftToBuy) {
            setInvalidMessage(`Você pode comprar no máximo mais ${quantityLeftToBuy} números da sorte`);
            return;
        }

        setLoading(true);

        gtag('event', 'draw_ticket_shopping_submit', { quantity });
        try {
            const data = {
                quantity: parseInt(quantity),
                resellerId: getResellerId(),
                yearMonth: drawTickets.yearMonth,
            };
            const response = await buyDrawTickets(data, navigate);
            setLoading(false);

            if (response.success) {
                setDrawTicketPaymentUrlResponse(response);
            } else {
                setInvalidMessage(response.error || 'Erro ao finalizar a compra');
            }
        } catch (error) {
            Sentry.captureException(error);
            setLoading(false);
            setError(new Error(`Erro ao comprar o número da sorte. ${error}`));
        }
    }

    async function copyCode(code: string) {
        try {
            await navigator.clipboard.writeText(code);
            setCopiedMessage('Código copiado com sucesso');
        } catch (error: any) {
            setCopiedMessage('Erro ao copiar o Código');
            console.error(error);
            gtag('event', 'error_copy_qrcode');
            Sentry.captureException(error);
        }
    }

    function navigateToTickets() {
        navigate('/vale-compras-premiado/bilhetes');
    }

    if (error) throw error;

    if (!drawTickets) return <Spinner />;

    return (
        <Container>
            <LogoContainer>
                <VendahLogo />
            </LogoContainer>
            <TitleContainer>
                <Title>Vale Compras Premiado</Title>
            </TitleContainer>
            <DrawTicketCustomerDetails />
            {drawTickets && (
                <>
                    <Padding />
                    <Form onSubmit={submitForm}>
                        <InputsContainer>
                            <Input
                                id="quantity"
                                value={quantity}
                                label="Números da sorte"
                                placeholder="Digite a quantidade de número da sorte"
                                mask="99"
                                maxLength={2}
                                onValueChange={setQuantity}
                                invalidMessage={invalidMessage}
                            />
                        </InputsContainer>
                        <Padding />
                        {!drawTicketPaymentUrlResponse && (
                            <SubmitButton disabled={loading || !drawTickets}>
                                <Title>Comprar</Title>
                            </SubmitButton>
                        )}
                    </Form>
                    {drawTicketPaymentUrlResponse && (
                        <>
                            <LinkButton color="pink" href={drawTicketPaymentUrlResponse.paymentUrl}>
                                <Title>Pagar com Cartão, etc</Title>
                            </LinkButton>
                            {!paymentQrcode?.success && (
                                <>
                                    {qrcodeInvalidEmail && (
                                        <EmailContainer>
                                            <strong>Digite seu E-mail para gerar o PIX</strong>
                                            <Input
                                                id="email"
                                                value={email}
                                                label="E-mail"
                                                onValueChange={text => setEmail(text.trim())}
                                                placeholder="E-mail"
                                                invalidMessage={emailInvalid ? 'E-mail inválido' : undefined}
                                            />
                                        </EmailContainer>
                                    )}
                                    <ActionButton
                                        disabled={loadingQrcode || (qrcodeInvalidEmail && emailInvalid)}
                                        onPress={() =>
                                            showQrcode(
                                                drawTicketPaymentUrlResponse.externalReference,
                                                drawTicketPaymentUrlResponse.totalAmount
                                            )
                                        }
                                    >
                                        <Title>{loadingQrcode ? 'Carregando...' : 'Pagar com PIX'}</Title>
                                    </ActionButton>
                                </>
                            )}
                            {paymentQrcode?.success && (
                                <div>
                                    {paymentQrcode.qr_code_base64 && (
                                        <QRCodeContainer>
                                            <img
                                                src={`data:image/png;base64, ${paymentQrcode.qr_code_base64}`}
                                                alt="Red dot"
                                            />
                                        </QRCodeContainer>
                                    )}
                                    <QRCodeButtonContainer>
                                        <ActionButton
                                            disabled={false}
                                            color={'blue'}
                                            onPress={() => copyCode(paymentQrcode.qr_code)}
                                        >
                                            <Copy />
                                            <Padding />
                                            <Title>Copiar código Pix Copia e Cola</Title>
                                        </ActionButton>
                                        {copiedMessage && <p>{copiedMessage}</p>}
                                    </QRCodeButtonContainer>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
            <ActionButton color="blue" onPress={() => navigateToTickets()}>
                <Title>Meus números da sorte</Title>
            </ActionButton>
        </Container>
    );
}

export default DrawTicketShopPage;
